import React, { useEffect, useState } from 'react';
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';
import InputField from '../common/Input';
import WithRights from '../common/WithRights';
import { Role, TENANT } from '../../config/constant';
import { toast } from 'react-toastify';
const SalesMappedDealer = (props) => {
    // const [tipOpen, setTipOpen] = useState(false);
    // const [tipOpenId, setTipOpenId] = useState(0);//removed warning
    const [search_text, setSearchText] = useState('')
    const [assigned_dealer_data, setAssignedDealerData] = useState(null)
    const [unassigned_dealer_data, setUnassignedDealerData] = useState(null)

    useEffect(() => {
        setAssignedDealerData(props.assigned_dealer)
        setUnassignedDealerData(props.unAssigned_dealer)
        // setTipOpenId('')//removed warning
    }, [props.assigned_dealer, props.unAssigned_dealer])

    useEffect(() => {
        setSearchText("")
    }, [props.search_reset])

    // const toggleTip = (id, state) => {//removed warning
    //     if (state === 'close') {
    //         id = ''
    //     }
    //     setTipOpen(!tipOpen)
    //     setTipOpenId(id)
    // }

    const handleInputChange = async (target_name, type, event) => {
        let { value } = event.target
        if (target_name === "search_text") {
            setSearchText(value)
            if (value.length > 2) {
                let params = { "vertical_id": 1, "page": 1, status: '1', per_page: 50, search_text: value }
                await props.handleUnAssignedDealer(params, assigned_dealer_data)
            }
            if (value.length == 0) {
                let params = { "vertical_id": 1, "page": 1, status: '1', per_page: 50, search_text: "" }
                await props.handleUnAssignedDealer(params, assigned_dealer_data)
            }
        }
    }

    const handleClick = (element, index, type) => { 

        let temp_unassigned_dealer_data = [...unassigned_dealer_data]
        let temp_assigned_dealer_data = [...assigned_dealer_data] 
        if (type === 'unassigned') {
            let bm_role_id = (TENANT=="id" || TENANT=="tri-id") ? Role.BM : Role.MM
            let assigned_data_data = element.assigned_executive_data.length ? 1 : 0; 
            let is_ucf_freelancer_assigned = element.assigned_executive_data.some(item => item.business_line === 'UCF' && [Role["Agent"], Role["ARO"], Role["BRO"]].includes(+item.role_id));
            let is_ucf_bm_assigned = element.assigned_executive_data.some(item => item.business_line === 'UCF' && [bm_role_id].includes(+item.role_id));
            
            if ([Role["Agent"], Role["ARO"], Role["BRO"]].includes(parseInt(props.user_detail.role_id)) && props.user_detail.business_line === 'UCF' && is_ucf_bm_assigned) {
                toast.warn("Warning: UCF BM already mapped to this dealer");
            } else if ([bm_role_id].includes(parseInt(props.user_detail.role_id)) && props.user_detail.business_line === 'UCF' && is_ucf_freelancer_assigned) {
                toast.warn('Warning: UCF Freelancer already mapped to this dealer');
            }

            // if (TENANT!="fav-id" && parseInt(props.user_detail.role_id) === bm_role_id && is_ucf_bm_assigned) {
            //     toast.error(TENANT=="fav-id" ? "Dealer cannot be mapped to any users when mapped to MM" : "Dealer cannot be mapped to any users when mapped to BM")
            // } else {
            //     let bm_data = element.assigned_executive_data.filter(e => e.role_id === bm_role_id)
            //     if (TENANT!="fav-id" && bm_data && bm_data.length) {
            //         toast.error(TENANT=="fav-id" ? "Dealer cannot be mapped to any users when mapped to MM"  : "Dealer cannot be mapped to any users when mapped to BM")
            //     } else {
            //         temp_unassigned_dealer_data = temp_unassigned_dealer_data.filter((e) => e.dealer_id != element.dealer_id)
            //         temp_assigned_dealer_data.unshift(element)
            //     }
            // } 

            temp_unassigned_dealer_data = temp_unassigned_dealer_data.filter((e) => e.dealer_id != element.dealer_id)
            temp_assigned_dealer_data.unshift(element)
        }
        if (type === 'assigned') {
            temp_assigned_dealer_data = temp_assigned_dealer_data.filter((e) => e.dealer_id != element.dealer_id)
            temp_unassigned_dealer_data.unshift(element)
        }
        setAssignedDealerData(temp_assigned_dealer_data)
        setUnassignedDealerData(temp_unassigned_dealer_data)
    }

    const handleSubmit = async () => {
        let assigned_dealer_id = assigned_dealer_data.map(({ dealer_id }) => dealer_id)
        props.updateAssignDealer(assigned_dealer_id)
        setSearchText("")
    }

    return (
        <React.Fragment>
            <div className='sales-users-left-panel'>
                <h3>Sales Dealers</h3>
                <div className='sales-mapped-bg'>
                    <fieldset class="search-bx">
                        <div class="material">
                            <InputField type="text" name="search_text" id="search_text" placeholder="Search Dealer" value={search_text} onChange={handleInputChange.bind(null, 'search_text', 'text')} />
                            <i class="ic-search"></i>
                        </div>
                    </fieldset>
                    <div className='sales-user-list'>
                        <ul>
                            {unassigned_dealer_data && unassigned_dealer_data.length > 0 && unassigned_dealer_data.map((element, index) => (
                                <li key={index}>
                                    <label onClick={(e) => handleClick(element, index, 'unassigned')}>{element.name + (element.dealer_id ? " (GCD - " + element.dealer_id + ")" : "")}</label>
                                    {element.dealercount > 0 &&
                                        // <span className='mapped_user_toggle'>{element.dealercount + " Mapped Users"}
                                        // <i onClick={() => toggleTip(element.dealer_id, 'open')} className='ic-info-icon1 m-sm-l'></i>
                                        //     <span  title="" className="tooltio-ic">
                                                
                                        //     </span>
                                        //     {/* <span onClick={() => toggleTip(element.dealer_id, 'open')} title="" className="tooltio-ic">
                                        //         <i className='ic-info-icon1 m-sm-l'></i>
                                        //     </span> */}
                                        //     <Tooltip
                                        //         content={(
                                        //             <div className="controlled-example tooltip-main">
                                        //                 <div className="tooltip-data">
                                        //                     <span className="controlled-example_close-button" onClick={() => toggleTip(element.dealer_id, 'close')}><i className="ic-clearclose"></i></span>
                                        //                     <ul className="user-detail">
                                        //                         {element.assigned_executive_data?.length && element.assigned_executive_data.map((executive_element, executive_index) => (
                                        //                             <li className='popup-list' key={executive_index}>{executive_element.name+" - "+executive_element.user_id}{executive_element.is_account_manager == 1 && <span className='account-manager-span'>Account Manager</span>}</li>
                                        //                         ))}
                                        //                     </ul>
                                        //                 </div>
                                        //             </div>
                                        //         )}
                                        //         isOpen={(element.dealer_id === tipOpenId) && tipOpen ? true : false}
                                        //         tagName="span"
                                        //         direction="down"
                                        //         className="tootip-outer"
                                        //         forceDirection
                                        //     >
                                        //     </Tooltip>
                                        // </span>
                                        <Tooltip
                                            useContext
                                            html={(
                                                    <div className="controlled-example tooltip-main">
                                                         <div className="tooltip-data tootip-outer">
                                                             <ul className="user-detail">
                                                                 {element.assigned_executive_data?.length && element.assigned_executive_data.map((executive_element, executive_index) => (
                                                                     <li className='popup-list' key={executive_index}>{executive_element.name+" - "+executive_element.user_id}{TENANT=="id" && executive_element.is_account_manager == 1 && <span className='account-manager-span'>Account Manager</span>}</li>
                                                                 ))}
                                                             </ul>
                                                         </div>
                                                     </div>
                                                 )}
                                            className="tootip-outer"
                                            // open="true"
                                            theme="light"
                                            size="regular"
                                            position="bottom"
                                            trigger="click"
                                        >
                                            <span className='mapped_user_toggle'>{element.dealercount + " Mapped Users"}
                                            <span title="" className="tooltio-ic"> <i className='ic-info-icon1'></i>
                                            </span>
                                            </span>
                                        </Tooltip> 
                                    }
                                    <i onClick={(e) => handleClick(element, index, 'unassigned')} className='ic-arrow_forward listclose-ic'></i>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className='mapped-users-right-panel'>
                <h3>Mapped Sales Dealers</h3>
                <div className='sales-mapped-bg'>

                    <div className='sales-user-list'>
                        <ul>
                            {assigned_dealer_data && assigned_dealer_data.length > 0 && assigned_dealer_data.map((element, index) => (
                                <li key={index}>
                                    <label>{element.name + (element.dealer_id ? " (GCD - " + element.dealer_id + ")" : "")}</label>
                                    {element.dealercount > 0 &&
                                        <Tooltip
                                            useContext
                                            html={(
                                                    <div className="controlled-example tooltip-main">
                                                        <div className="tooltip-data tootip-outer">
                                                            <ul className="user-detail">
                                                                {element.assigned_executive_data?.length && element.assigned_executive_data.map((executive_element, executive_index) => (
                                                                    <li className='popup-list' key={executive_index}>{(executive_element.name ? executive_element.name + " - "+executive_element.user_id : "") + (executive_element.role_name ? " (" + executive_element.role_name + ")" : "") + (executive_element.manager_name ? ", " + executive_element.manager_name : "") + (executive_element.manager_role_name ? " (" + executive_element.manager_role_name + ")" : "")}{TENANT=="id" && executive_element.is_account_manager == 1 && <span className='account-manager-span'>Account Manager</span>}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            className="tootip-outer"
                                            theme="light"
                                            size="regular"
                                            position="bottom"
                                            trigger="click"
                                            >
                                                <span className='mapped_user_toggle'>{element.dealercount + " Mapped Users"}
                                                <span title="" className="tooltio-ic"> <i className='ic-info-icon1'></i>
                                                </span>
                                                </span>
                                        </Tooltip> 
                                    }
                                    <i onClick={(e) => handleClick(element, index, 'assigned')} className='ic-clearclose listclose-ic'></i>
                                </li>
                            ))}

                        </ul>
                        <WithRights rightKey="update_dealer_mapping">
                        <div onClick={() => handleSubmit()} className='btn-submit'>
                            <button className='btn-primary'>Submit</button>
                        </div>
                        </WithRights>
                    </div>
                </div>
            </div>
           {(TENANT =="id") && <div className='mapped-users-right-panel' style={{ "display": 'none' }}>
                <h3>Mapped Sales Users</h3>
                <div className='sales-mapped-bg'>

                    <div className='sales-user-list no-data-txt'>
                        <ul>
                            <li>Move Agent, BRO here</li>
                        </ul>
                    </div>
                </div>
            </div>
}
        </React.Fragment>
    )
    // }
}
export default SalesMappedDealer;