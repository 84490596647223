import React, { useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import ReactMultiSelectCheckboxes from "../common/ReactMultiSelectCheckboxes";
import { toast } from "react-toastify";
import { MasterService } from "../../services";
import Loader from "../common/loader";

const TriTargetConfiguration = () => {
    const [loading, setLoading] = useState(false);
    const [configs, setConfigs] = useState([{ id: 1, financier_ids: [], points: "" }]);
    const [allFinanciers, setAllFinanciers] = useState([]);
    const [errors, setErrors] = useState({});

    const getConfigData = async () => {
        try {
            setLoading(true);
            const response = await MasterService.post("/sfa/user/get_tri_target_config_list", {});
            if (response?.data?.status === 200 && response?.data?.data) {
                const { configs: configData, allFinanciers: financierData } = response.data.data;
                setConfigs(configData);
                setAllFinanciers(financierData);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            let hasError = false;
            let tempErrors = {};

            configs.forEach((config) => {
                if (!config.financier_ids || config.financier_ids.length === 0) {
                    tempErrors[`financiers_${config.id}`] = "Please select at least one financier";
                    hasError = true;
                }

                if (!config.points) {
                    tempErrors[`points_${config.id}`] = "Please enter points";
                    hasError = true;
                } else if (isNaN(config.points) || parseFloat(config.points) < 0) {
                    tempErrors[`points_${config.id}`] = "Please enter valid points";
                    hasError = true;
                }
            });

            if (hasError) {
                setErrors(tempErrors);
                return;
            }

            const formattedData = configs.map((config) => ({
                id: config.id,
                points: parseFloat(config.points),
                financier_ids: config.financier_ids,
            }));

            const response = await MasterService.post("/sfa/user/save_tri_target_config", {
                configs: formattedData,
            });

            if (response?.data?.status === 200) {
                toast.success("Configuration saved successfully");
                getConfigData();
            } else {
                toast.error(response?.data?.message || "Failed to save configuration");
            }
        } catch (error) {
            toast.error("Failed to save configuration");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getAvailableFinanciers = (configId) => {
        const selectedFinanciers = configs.filter((config) => config.id !== configId).flatMap((config) => config.financier_ids);

        return allFinanciers.filter((financier) => !selectedFinanciers.includes(financier.id));
    };

    const handleChangeMultiSelect = (configId, selectedOptions) => {
        setConfigs(
            configs.map((config) =>
                config.id === configId
                    ? {
                          ...config,
                          financier_ids: selectedOptions.map((opt) => opt.id),
                      }
                    : config
            )
        );

        let tempErrorsCopy = { ...errors };
        tempErrorsCopy[`financiers_${configId}`] = "";
        setErrors(tempErrorsCopy);
    };

    const handlePointsChange = (e, configId) => {
        setConfigs(configs.map((config) => (config.id === configId ? { ...config, points: e.target.value } : config)));
    };

    const onKeyDown = (e) => {
        if (e.key === "-" || e.key === "e") {
            e.preventDefault();
        }
    };

    const addNewConfig = () => {
        setConfigs([...configs, { id: configs.length + 1, financier_ids: [], points: "" }]);
    };

    useEffect(() => {
        getConfigData();
    }, []);

    const getSelectedFinanciers = (financier_ids) => {
        return allFinanciers.filter((financier) => financier_ids.includes(financier.id));
    };

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="container-fluid">
                <div className="top-heading">
                    <h1>Sales Target</h1>
                </div>

                <div className="add-incentive-accordion">
                    <Accordion allowZeroExpanded={false} preExpanded={["conf-1"]}>
                        <AccordionItem uuid="conf-1">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <h2>User Target Configuration</h2>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="sub-accordion">
                                    <div className="left-right-panel-outer progression-left-right">
                                        <div className="add-incenative-left-panel">
                                            <div className="app-submit-outer add-incentive-table Progression-table-outer">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Financier</th>
                                                            <th>Average Points</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {configs.map((config, index) => (
                                                            <tr key={config.id}>
                                                                <td>
                                                                    <fieldset className="multiselect-dropDown">
                                                                        <div className="material">
                                                                            <ReactMultiSelectCheckboxes
                                                                                isMulti
                                                                                options={[
                                                                                    { label: "Select All", value: "*" },
                                                                                    ...getAvailableFinanciers(config.id),
                                                                                ]}
                                                                                placeholderButtonLabel="Select Financier"
                                                                                iconAfter="false"
                                                                                value={getSelectedFinanciers(config.financier_ids)}
                                                                                onChange={(selected) => handleChangeMultiSelect(config.id, selected)}
                                                                            />
                                                                            {errors[`financiers_${config.id}`] && (
                                                                                <span className="error show">{errors[`financiers_${config.id}`]}</span>
                                                                            )}
                                                                        </div>
                                                                    </fieldset>
                                                                </td>
                                                                <td>
                                                                    <fieldset>
                                                                        <div className="material">
                                                                            <input
                                                                                type="number"
                                                                                step="0.01"
                                                                                onKeyDown={onKeyDown}
                                                                                placeholder=" "
                                                                                name={`points_${index}`}
                                                                                onChange={(e) => handlePointsChange(e, config.id)}
                                                                                className="form-input"
                                                                                value={config.points || ""}
                                                                            />
                                                                        </div>
                                                                        {errors[`points_${config.id}`] && (
                                                                            <span className="error show">{errors[`points_${config.id}`]}</span>
                                                                        )}
                                                                    </fieldset>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <button onClick={addNewConfig} className="btn-line m-xs-t">
                                        + Add More
                                    </button>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>

                <div className="btn-submit-reset text-right m-md-t">
                    <button onClick={handleSave} className="btn-primary">
                        Save
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default TriTargetConfiguration;
